<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="狀態" prop="status" align="center">
        <template slot-scope="scope">
          <p
            :class="{
              'enabled-status': scope.row.enabled,
            }"
          >
            {{ scope.row.status }}
          </p>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="範本名稱" prop="name" align="center" />
      <BaseElTableColumn label="建立時間" prop="createdAt" align="center" />
      <BaseElTableColumn label="修改時間" prop="updatedAt" align="center" />
      <BaseElTableColumn label="建立者" prop="operator" align="center" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            :editBtn="scope.row.isSystem ? '查看' : '編輯'"
            hideDelete
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <DeleteDialog
      v-if="modal.delete"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteRow"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import { get, map } from 'lodash'
import { useRouter } from 'vue-router/composables'

const DeleteDataAPI = () => {}

export default defineComponent({
  name: 'DataTable',
  components: { EmptyBlock },
  props: {
    tableData: { type: Array, defalt: () => [] },
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const router = useRouter()
    const { shopId, dateFormat } = useTable()
    const selectRow = ref(null)
    const modal = reactive({
      delete: false,
    })

    const curCardStyle = computed(() => {
      const styleName = get(props.configData, 'cardStyle')
      if (styleName === 'custom') return get(props.configData, 'CustomCardTemplateId')
      return `@system-${styleName}`
    })

    const displayData = computed(() => {
      const data = props.tableData
      return map(data, (i) => {
        const isSystem = i.isSystem
        let enabled = false

        if (i.id === curCardStyle.value) enabled = true

        return {
          status: enabled ? '啟用中' : '未啟用',
          enabled,
          id: i.id,
          name: i.displayName || i.name,
          style: i.name,
          isSystem,
          createdAt: isSystem ? '-' : dateFormat(i.createdAt),
          updatedAt: isSystem ? '-' : dateFormat(i.updatedAt),
          operator: get(i, 'Creator.name'),
        }
      })
    })

    const onRowEdit = (row) => {
      router.push({
        name: 'MemberCardEdit',
        params: {
          styleName: row.style,
          id: row.id || undefined,
        },
      })
    }

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const deleteRow = async () => {
      const [, err] = await DeleteDataAPI({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('刪除成功！')
      modal.delete = false
      emit('refresh')
    }

    return {
      modal,
      displayData,
      onRowEdit,
      onRowDelete,
      deleteRow,
    }
  },
})
</script>

<style scoped lang="postcss">
.enabled-status {
  @apply text-success;
}
</style>
